import React, { useCallback, useContext, useEffect, useState } from 'react';
import AuthContext from './FirebaseAuthContext';
import { getUserCompany } from 'src/models/Companies';
import LoadingScreen from 'src/components/LoadingScreen';

const UserContext = React.createContext(undefined);

export const UserContextProvider = props => {
  // TODO: To be replaced with session context
  const [userData, setUserData] = useState({
    userId: undefined,
    stripeCustomerId: undefined,
    companyID: undefined,
    companyName: undefined,
    companyEmail: undefined,
    companyPhone: undefined,
    companyActivated: undefined,
    signupCompleted: undefined,
    companyLocationHQ: undefined,
    baseCurrency: undefined,
    salesTaxAccounts: undefined,
    transactionLockedDate: undefined,
    subscriptionCanceled: undefined,
    subscriptionEndDate: undefined,
    dateCreated: undefined,
  });
  const [loading, setLoading] = useState(true);
  const FirebaseAuthContext = useContext(AuthContext);

  const fetchCompany = useCallback(async () => {
    const companyID = await getUserCompany(FirebaseAuthContext.user.id);
    setLoading(false);

    const salesTaxArray = [];
    let subscriptionCanceled = null;
    let subscriptionEndDate = null;
    if (companyID.companyData !== null) {
      subscriptionCanceled = companyID?.activeSubscription?.canceled;
      subscriptionEndDate = companyID?.activeSubscription?.endDate;
      companyID?.salesTaxObject?.map(item => {
        salesTaxArray.push(item);
      });
      setUserData({
        userId: FirebaseAuthContext.user.id,
        stripeCustomerId: companyID.stripeCustomerId,
        companyID: companyID.company_id,
        companyName: companyID.company_legal_name,
        companyEmail: companyID.primary_email,
        companyPhone: companyID.primary_phone,
        companyActivated: companyID.activated,
        signupCompleted: true,
        companyLocationHQ: companyID.company_location_of_headquarters,
        baseCurrency: companyID.base_currency,
        salesTaxAccounts: salesTaxArray,
        transactionLockedDate: companyID.transactionLockedDate
          ? new Date(companyID.transactionLockedDate._seconds * 1000)
          : null,
        subscriptionCanceled: subscriptionCanceled,
        subscriptionEndDate: subscriptionEndDate,
        dateCreated: new Date(companyID.date_created._seconds * 1000),
      });
    } else {
      setUserData({
        userId: FirebaseAuthContext.user.id,
        stripeCustomerId: null,
        companyID: null,
        companyName: null,
        companyEmail: null,
        companyPhone: null,
        companyActivated: false,
        signupCompleted: false,
        companyLocationHQ: null,
        baseCurrency: null,
        salesTaxAccounts: null,
        transactionLockedDate: null,
        subscriptionCanceled: null,
        subscriptionEndDate: null,
        dateCreated: null,
      });
    }
  }, [FirebaseAuthContext]);

  useEffect(() => {
    if (FirebaseAuthContext.isAuthenticated) {
      if (userData.companyID === undefined) {
        fetchCompany();
      }
    } else {
      setLoading(false);
    }
  }, [userData, FirebaseAuthContext]);

  return (
    <UserContext.Provider value={userData}>
      {loading ? <LoadingScreen /> : props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
