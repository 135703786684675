/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  CreditCard as CreditCardIcon,
  Folder as FolderIcon,
  Cpu as CpuIcon,
  Loader as Recommend,
  BarChart as BarChartIcon,
  FileText,
  Settings as SettingsIcon,
  Send as SendIcon,
  Archive as ArchiveIcon,
  Users as UsersIcon,
  UserCheck as userCheck,
  CheckSquare as CheckSquareIcon,
  User as UserIcon,
  Zap as zapIcon,
  Layout as ReportsIcon,
} from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import UserContext from 'src/contexts/UserContext';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  onDesktopClose: () => void;
  openDesktop: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Accounting',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard',
      },
      {
        title: 'Financial Reports',
        icon: ReportsIcon,
        href: '/app/financial-statements',
        info: () => <Chip color="secondary" size="small" label="New" />,
      },
      {
        title: 'Recommendations',
        icon: Recommend,
        href: '/app/recommendations',
      },
      {
        title: 'Tax Filing',
        href: '/app/management/taxes',
        icon: CheckSquareIcon,
        info: () => <Chip color="secondary" size="small" label="New" />,
      },
    ],
  },
  {
    subheader: 'Bookkeeping',
    items: [
      {
        title: 'AI Bookkeeper',
        icon: zapIcon,
        href: '/app/aibookkeeper',
        info: () => <Chip color="secondary" size="small" label="New" />,
      },
      {
        title: 'Automation Formulas',
        icon: CpuIcon,
        href: '/app/automation/overview',
      },
      // {
      //   title: 'Payroll',
      //   icon: userCheck,
      //   href: '/app/payroll/employee',
      // },
      {
        title: 'Smart Shoebox',
        href: '/app/management/receipts',
        icon: ArchiveIcon,
        info: () => <Chip color="secondary" size="small" label="New" />,
      },
      {
        title: 'Transactions',
        icon: FileText,
        href: '/app/management/transactions',
      },
    ],
  },
  {
    subheader: 'Sales',
    items: [
      // {
      //   title: 'Customers',
      //   icon: UsersIcon,
      //   href: '/app/management/customers',
      //   info: () => <Chip color="secondary" size="small" label="New" />,
      // },
      {
        title: 'Invoicing',
        href: '/app/management/invoices',
        icon: SendIcon,
      },
    ],
  },
  {
    subheader: 'Profile',
    items: [
      {
        title: 'Bank Accounts',
        href: '/app/account/bank',
        icon: CreditCardIcon,
      },
      {
        title: 'Documents',
        icon: FolderIcon,
        // href: 'https://drive.google.com/drive/u/0/my-drive',
        href: '/app/reports/documents',
      },
      {
        title: 'Settings',
        href: '/app/profile',
        icon: UserIcon,
      },
    ],
  },
  // {
  //   subheader: 'Settings',
  //   items: [
  //     {
  //       title: 'Calendar',
  //       href: '/app/coming-soon',
  //       // href: '/app/calendar',
  //       icon: CalendarIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Coming Soon"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/app/coming-soon',
  //       // href: '/app/chat',
  //       icon: MessageCircleIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Coming Soon"
  //         />
  //       )
  //     },
  //   ],
  // },
  // {
  //   subheader: 'Auth',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   items: [
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Dashboard',
  //       icon: PieChartIcon,
  //       href: '#'
  //     },
  //     {
  //       title: 'Projects Platform',
  //       href: '/app/projects',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Overview',
  //           href: '/app/projects/overview'
  //         },
  //         {
  //           title: 'Browse Projects',
  //           href: '/app/projects/browse'
  //         },
  //         {
  //           title: 'Create Project',
  //           href: '/app/projects/create'
  //         },
  //         {
  //           title: 'View Project',
  //           href: '/app/projects/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Platform',
  //       href: '/app/social',
  //       icon: ShareIcon,
  //       items: [
  //         {
  //           title: 'Profile',
  //           href: '/app/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           href: '/app/social/feed'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Kanban',
  //       href: '/app/kanban',
  //       icon: TrelloIcon
  //     },
  //     {
  //       title: 'Mail',
  //       href: '/app/mail',
  //       icon: MailIcon
  //     },
  //     {
  //       title: 'Customers',
  //       icon: UsersIcon,
  //       href: '/app/management/customers',
  //       items: [
  //         {
  //           title: 'List Customers',
  //           href: '/app/management/customers'
  //         },
  //         {
  //           title: 'View Customer',
  //           href: '/app/management/customers/1'
  //         },
  //         {
  //           title: 'Edit Customer',
  //           href: '/app/management/customers/1/edit'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Products',
  //       icon: ShoppingCartIcon,
  //       href: '#',
  //       items: [
  //         {
  //           title: 'List Products',
  //           href: '/app/management/products'
  //         },
  //         {
  //           title: 'Create Product',
  //           href: '/app/management/products/create'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Orders',
  //       icon: FolderIcon,
  //       href: '/app/management/orders',
  //       items: [
  //         {
  //           title: 'List Orders',
  //           href: '/app/management/orders'
  //         },
  //         {
  //           title: 'View Order',
  //           href: '/app/management/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Editors',
  //       href: '/app/extra/editors',
  //       icon: LayoutIcon,
  //       items: [
  //         {
  //           title: 'DraftJS Editor',
  //           href: '/app/extra/editors/draft-js'
  //         },
  //         {
  //           title: 'Quill Editor',
  //           href: '/app/extra/editors/quill'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 41,
    height: 'calc(100% - 41px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar: FC<NavBarProps> = ({
  onMobileClose,
  openMobile,
  onDesktopClose,
  openDesktop,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const companyData = useContext(UserContext);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (openDesktop && onDesktopClose) {
      onDesktopClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden> */}
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/profile">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/profile"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            {/* <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography> */}
          </Box>
        </Box>
        <Divider />
        {companyData?.companyLocationHQ?.includes('CA-') ? (
          <Box p={2}>
            {sections.map(section => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        ) : (
          <Box p={2}>
            {sections
              .filter(section =>
                section.items.some(item => item.title !== 'Tax Filing')
              )
              .map(section => (
                <List
                  key={section.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items.filter(
                      item => item.title !== 'Tax Filing'
                    ),
                    pathname: location.pathname,
                  })}
                </List>
              ))}
          </Box>
        )}

        {/* <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/app/account/ticket"
              // href="/app/account/ticket"
            >
              Submit a Ticket
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          onClose={onDesktopClose}
          open={openDesktop}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onDesktopClose: PropTypes.func,
  openDesktop: PropTypes.bool,
};

export default NavBar;
