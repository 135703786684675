import React, { useRef, useState } from 'react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  MenuItem,
  makeStyles,
  Menu,
  Tooltip,
  SvgIcon,
  IconButton,
} from '@material-ui/core';

import { Theme } from 'src/theme';
import { HelpCircle } from 'react-feather';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 200,
  },
  list: {
    padding: theme.spacing(1, 3),
  },
  listItemText: {
    marginRight: theme.spacing(1),
  },
  lastActivity: {
    whiteSpace: 'nowrap',
  },
}));

const Contact: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleMenuItemClick = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Help">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon>
            <HelpCircle />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={() => {
            handleClose(); // Close the menu
            window.open('https://www.reinvestwealth.com/resources');
          }}
        >
          View Resources (Free)
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/account/ticket"
          onClick={handleMenuItemClick}
        >
          Get Tech Support (Free)
        </MenuItem>
        <MenuItem disabled>AI Chat (coming 2025)</MenuItem>
        <MenuItem
          onClick={() => {
            handleClose(); // Close the menu
            window.open(
              'https://meetings.hubspot.com/reinvestwealth/meet-with-an-accountant'
            );
          }}
        >
          Call a CPA ($49/30min)
        </MenuItem>
      </Menu>
    </>
  );
};

export default Contact;
