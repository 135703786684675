import React, { useContext, useEffect } from 'react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Tooltip,
} from '@material-ui/core';
import { Menu as MenuIcon, Gift } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import { Theme } from 'src/theme';
import Account from './Account';
import GetApp from './GetApp';
import Settings from './Settings';
import Contact from './Contact';
import { useSnackbar } from 'notistack';
import UserContext from 'src/contexts/UserContext';
import Badge from '@material-ui/core/Badge';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  onDesktopNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: '#ffffff',
          color: 'black',
        }
      : {}),
    ...(theme.name === THEMES.DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 41,
    maxHeight: 41,
  },
  wiggle: {
    animation: '$wiggle 2s ease',
  },
  '@keyframes wiggle': {
    '0%, 100%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(-10deg)' },
    '50%': { transform: 'rotate(10deg)' },
    '75%': { transform: 'rotate(-10deg)' },
  },
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  onDesktopNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [isWiggling, setIsWiggling] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const companyId = userContext.companyID;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsWiggling(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`www.reinvestwealth.com?ref=${companyId}`);
    enqueueSnackbar('Link Copied', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  return (
    <>
      <AppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <IconButton color="inherit" onClick={onDesktopNavOpen}>
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
            <RouterLink to="/app/reports/dashboard" style={{ paddingRight: 5 }}>
              <Logo />
            </RouterLink>
            <RouterLink
              to="/app/reports/dashboard"
              style={{ textDecoration: 'none' }}
            >
              <Typography
                color="textPrimary"
                variant="h3"
                style={{ fontFamily: 'Times' }}
              >
                ReInvestWealth
              </Typography>
            </RouterLink>
          </Hidden>
          <Box ml={2} flexGrow={1} />
          <GetApp />
          <Tooltip title="Rewards">
            <IconButton color="inherit" onClick={handleOpenDialog}>
              <Badge variant="dot" color="error">
                <SvgIcon
                  fontSize="small"
                  className={isWiggling ? classes.wiggle : ''}
                >
                  <Gift />
                </SvgIcon>
              </Badge>
            </IconButton>
          </Tooltip>

          <Settings />
          <Contact />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </AppBar>

      {isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
            <img
              src="/static/images/recommendations/reinvestwealth-referral-reward-100.png"
              alt="Referral Reward"
              style={{ width: '70%' }}
            />
            <Typography variant="h2" style={{ fontSize: '2.5rem' }}>
              {'Refer a Friend and Get a $50 Amazon Gift Card! 💌'}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: 'center' }}
            >
              Receive a $50 Amazon gift card by email, when your referral signs
              up to the ReInvestWealth Accounting Software using your link.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleCopyLink}
            >
              Copy Your Referral Link
            </Button>
          </DialogActions>
          <DialogActions
            style={{
              marginTop: 10,
              marginBottom: 15,
              justifyContent: 'center',
            }}
          >
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() =>
                window.open('https://www.reinvestwealth.com/referral')
              }
            >
              Terms & Conditions
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onDesktopNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
  onDesktopNavOpen: () => {},
};

export default TopBar;
